<template>
  <b-card title="Görüşme Durumları">
    <b-row v-show="statuses.chart.series.length > 0">
      <b-col>
        <apex-donut-chart
          :categories-data="statuses.chart.categories"
          :series-data="statuses.chart.series"
        />
      </b-col>
      <b-col class="d-flex align-items-center">
        <b-list-group class="w-100">
          <b-list-group-item
            v-for="item in statuses.data"
            :key="item.id"
          >
            <div class="d-flex justify-content-between">
              <div class="font-weight-bold text-primary">
                {{ item.title }}
              </div>
              <div class="font-weight-bold">
                {{ item.count }} Adet
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <empty-alert v-if="statuses.chart.series.length === 0" />
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import HorizontalBarChart from '@/views/Admin/Reports/charts/HorizontalBarChart.vue'
import EmptyAlert from '@/views/Admin/Reports/common/EmptyAlert.vue'
import ApexDonutChart from '@/views/Admin/Reports/charts/ApexDonutChart.vue'

export default {
  name: 'Statuses',
  components: {
    BCard,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    ApexDonutChart,
    HorizontalBarChart,
    EmptyAlert,
  },
  computed: {
    statuses() {
      return this.$store.getters['interviewReports/statuses']
    },
  },
}
</script>
