<template>
  <b-card no-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="details"
      striped
    >
      <template #cell(company)="data">
        <div class="d-flex align-content-start align-items-center">
          <div>
            <b-button
              v-b-tooltip.hover.top="'Görüşmeyi Görüntüle'"
              variant="flat-success"
              class="btn-icon rounded-circle"
              :href="'/app/interviews/view/' + data.item.id"
              target="_blank"
            >
              <FeatherIcon icon="EyeIcon" />
            </b-button>
          </div>
          <div class="ml-1">
            <div>
              {{ data.item.company }}
            </div>
            <div class="text-primary font-small-2">
              {{ data.item.username }}
            </div>
            <div class="text-muted font-small-2">
              {{ moment(data.item.created).format('llll') }}
            </div>
          </div>
        </div>
      </template>
      <template #cell(interview_subject)="data">
        <div>
          {{ data.item.interview_subject }}
        </div>
        <div class="font-small-2 text-primary">
          {{ data.item.meet }}
        </div>
      </template>
      <template #cell(interview_status)="data">
        <div>
          {{ data.item.interview_status }}
        </div>
        <div
          class="font-small-2"
          :class="data.item.status === '0'? 'text-danger' : 'text-success'"
        >
          {{ data.item.status === '0'? 'Açık Görüşme' : 'Kapalı Görüşme' }}
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <div class="d-flex justify-content-between align-items-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
        <b-button
          variant="success"
          size="sm"
          :href="downloadUrl"
          target="_blank"
        >
          <FeatherIcon icon="DownloadIcon" />
          Excel Aktar
        </b-button>
      </div>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard, BCardFooter, BButton, BTable, BPagination, VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'Details',
  components: {
    BCard,
    BTable,
    BPagination,
    BCardFooter,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          key: 'company',
          label: 'FİRMA ADI',
        },
        {
          key: 'interview_subject',
          label: 'KONU',
        },
        {
          key: 'interview_status',
          label: 'DURUM',
        },
      ],
    }
  },
  computed: {
    downloadUrl() {
      const urlParams = []
      if (this.filterParams.sdate) {
        urlParams.push(`sdate=${this.filterParams.sdate}`)
      }
      if (this.filterParams.edate) {
        urlParams.push(`edate=${this.filterParams.edate}`)
      }
      if (this.filterParams.id_users) {
        urlParams.push(`id_users=${this.filterParams.id_users}`)
      }
      const params = urlParams.join('&')
      let url = `${this.$store.state.app.baseURL}/Exports/reports/interviews?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
    filterParams() {
      return this.$store.getters['interviewReports/filterParams']
    },
    details() {
      return this.$store.getters['interviewReports/details']
    },
    dataCount() {
      return this.$store.getters['interviewReports/dataCount']
    },
  },
  watch: {
    currentPage(val) {
      this.filterParams.start = (val - 1) * this.perPage
      this.getReport()
    },
  },
  created() {
    this.getReport()
  },
  methods: {
    getReport() {
      this.$store.dispatch('interviewReports/getDetail', this.filterParams)
    },
  },
}
</script>
