<template>
  <div>
    <filter-data :get-report="getReport" />
    <b-tabs
      pills
      justified
    >
      <b-tab
        active
        lazy
      >
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Rapor Özeti</span>
        </template>
        <report-summary />
        <meets />
        <statuses />
        <subjects />
      </b-tab>
      <b-tab
        lazy
      >
        <template #title>
          <feather-icon icon="ListIcon" />
          <span>Rapor Detayı</span>
        </template>
        <report-detail />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import FilterData from '@/views/Admin/Reports/Interviews/components/FilterData.vue'
import ReportSummary from '@/views/Admin/Reports/Interviews/components/Summary.vue'
import Meets from '@/views/Admin/Reports/Interviews/components/Meets.vue'
import Statuses from '@/views/Admin/Reports/Interviews/components/Statuses.vue'
import Subjects from '@/views/Admin/Reports/Interviews/components/Subjects.vue'
import ReportDetail from '@/views/Admin/Reports/Interviews/components/Details.vue'
import { BTab, BTabs } from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BTab,
    BTabs,
    FilterData,
    ReportSummary,
    Meets,
    Statuses,
    Subjects,
    ReportDetail,
  },
  computed: {
    filterParams() {
      return this.$store.getters['interviewReports/filterParams']
    },
  },
  created() {
    this.getReport()
  },
  methods: {
    getReport() {
      this.getSummaryReport()
      this.getDetailReport()
    },
    getSummaryReport() {
      this.$store.dispatch('interviewReports/getReport', this.filterParams)
    },
    getDetailReport() {
      this.filterParams.limit = 25
      this.filterParams.start = 0
      this.$store.dispatch('interviewReports/getDetail', this.filterParams)
    },
  },
}
</script>
